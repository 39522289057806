import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import buildFormat from './app/format.js';
import myScript from './scripts.js';
import OwlCarousel from 'owl.carousel';
import modaal from 'modaal';
import {scrollfire} from "./app/scrolltrigger.js";
import anime from 'animejs';

import fontAwesome from "font-awesome/scss/font-awesome.scss";
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css";
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css";
import modaalCss from 'modaal/dist/css/modaal.css';
class ScrollReveal {
  constructor(options){
    this.options = options;
  }

  reveal(el, options, duration){
    $(el).css({
      visibility: "visible",
      opacity: 0,
    })
    scrollfire( el, function(){
      // console.log(el);
      var args = {
        targets: el,
        // opacity: 1,
        // translateY: -options.distance,
        // duration: 600
      }
      args = Object.assign(options,args);
      anime(args);
    }, {
      offset: window.innerHeight
    })
  }
}

class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();

    //SPメニューの高さ取得
    function menuHight() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }

      var $smpHeaderHeight = $('.l-header').height();
      var windowHeight = window.innerHeight;
      var winH = windowHeight - $smpHeaderHeight;

      console.log($smpHeaderHeight);

      //動かすターゲットを取得
      var targetSlidebarWrap = $('.c-slidebar-menu'),
        targetSlidebarMenu = $('.c-slidebar__parent'),
        targetSlidebarBtn = $('.c-slidebar-menu__parent');


      //いざ実行(クリックしたら処理を実行する)
      targetSlidebarBtn.on('click', function () {
        $('.c-slidebar-menu').toggleClass('is-active');

      });
    }

    //フッターメニューSPスライド
    function menuSlide() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }
      $('.l-footer__block').on('click', function () {
        $(this).children(".l-footer__menutitle").toggleClass('is-open');
        $(this).children(".l-footer__menulist.is-sub").slideToggle();
      })
    }

    //owlcarousel
    function owlCarousel() {
      var owls = $('.owl-carousel');
      //->スライダー
      owls.imagesLoaded(function () {
        $('.c-main-visual__slider').owlCarousel({
          items: 1,
          margin: 0,
          dots: false,
          loop: true,
          nav: false,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<img src="../assets/images/icon-slider-prev.svg" />', '<img src="../assets/images/icon-slider-next.svg" />'],
        });
      });
      //->カード_カルーセル
      owls.imagesLoaded(function () {
        $('.js-card-slider').owlCarousel({
          margin: 0,
          dots: false,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<img src="../assets/images/icon-slider-prev.svg" />', '<img src="../assets/images/icon-slider-next.svg" />'],
          responsive: {
            // breakpoint from 0 up
            0: {
              items: 1,
            },
            // breakpoint from 750 up
            750: {
              items: 3,
            }
          }
        });
      });
      owls.imagesLoaded(function () {
        $('.js-slider').owlCarousel({
          margin: 0,
          dots: false,
          items: 1,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<div class="c-block-slider__nav"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="c-block-slider__nav is-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
        });
      });
      owls.imagesLoaded(function () {
        $('.js-slider-lg').owlCarousel({
          dots: false,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          center: true,
          autoWidth: true,
          autoHeight: true,
          navText: ['<div class="c-block-slider-lg__nav"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="c-block-slider-lg__nav is-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
          responsive: {
            // breakpoint from 0 up
            0: {
              items: 1,
              margin: 16,
            },
            // breakpoint from 750 up
            750: {
              items: 3,
              margin: 24,
            }
          }
        });
      });


      //スマホのみ
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }
      $('.js-card-slider-sp').addClass('owl-carousel');
      //->カード_カルーセル
      owls.imagesLoaded(function () {
        $('.js-card-slider-sp').owlCarousel({
          margin: 16,
          dots: false,
          loop: true,
          nav: true,
          items:2,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: false,
          navText: ['<div class="c-block-slider__nav"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="c-block-slider__nav is-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],

        });
      });

    }
    //- スクロールリーバル
    function reveal() {
      function domEach(items, callback) {
        if (typeof items === "string") {
          var items = $(items);
        }
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          // callback = callback.bind(item)
          callback(i, item);
        }
      }
      window.sr = new ScrollReveal({duration: 600, mobile: true});
      var baseEasing = 'cubicBezier(0.175, 0.885, 0.32, 1.275)';
      var baseDistance = '8';

      sr.reveal(".c-main-visual", {
        duration: 1400,
        opacity: 1,
        //translateY: -baseDistance,
        delay: 900,
      }, 100);

      sr.reveal(".c-solution__wrap  > *",{
        scale: [0.9, 1],
        opacity: 1,
        duration: 600,
        delay: anime.stagger(100),
        translateX: [-baseDistance,0],
        easing: baseEasing
      });
      domEach(".c-card-sm__block", function(key, item){

        sr.reveal(item,{
          scale: {
            value: [0.9,1],
            // easing: "linear"
          },
          opacity: 1,
          duration: 600,
          delay: 100*key,
          translateY: [-baseDistance,0],
          easing: baseEasing
        } );
      });
    }

    // modaal
    function modaal() {
      // ====================
      // <HTML>
      // <a href="path/to/image.jpg" class="js-modal-image" data-modaal-desc="My Description">Show</a>
      // or
      // <button data-modaal-content-source="path/to/image.jpg" class="js-modal-image">Show</button>
      // ====================

      $('.js-modal-image').modaal({
        type: 'image'
      });
    }

    // コピーライトの年数自動出力
    function autoWriteYear() {
      $('.js-current-year').text(new Date().getFullYear());
    }

    function buttonClick() {
      $('.js-button-click').on('click',function() {
        $(this).toggleClass('is-active');
        $(this).children('i').toggleClass('fa-heart-o');
        $(this).children('i').toggleClass('fa-heart');
      });
      $('.js-button').on('click',function() {
        $(this).toggleClass('is-active');
      });
      $('.js-close').on('click',function() {
        event.preventDefault();
        $(this).parent().hide();
      });
      // $('.c-box-post__check input').on('click',function() {
      //   $(this).parent().toggleClass('is-active');
      // });
    }

    function cardSlider() {
      //pause.js
      (function() {
        var $ = jQuery,
          pauseId = 'jQuery.pause',
          uuid = 1,
          oldAnimate = $.fn.animate,
          anims = {};

        function now() { return new Date().getTime(); }

        $.fn.animate = function(prop, speed, easing, callback) {
          var optall = $.speed(speed, easing, callback);
          optall.complete = optall.old; // unwrap callback
          return this.each(function() {
            // check pauseId
            if (! this[pauseId])
              this[pauseId] = uuid++;
            // start animation
            var opt = $.extend({}, optall);
            oldAnimate.apply($(this), [prop, $.extend({}, opt)]);
            // store data
            anims[this[pauseId]] = {
              run: true,
              prop: prop,
              opt: opt,
              start: now(),
              done: 0
            };
          });
        };

        $.fn.pause = function() {
          return this.each(function() {
            // check pauseId
            if (! this[pauseId])
              this[pauseId] = uuid++;
            // fetch data
            var data = anims[this[pauseId]];
            if (data && data.run) {
              data.done += now() - data.start;
              if (data.done > data.opt.duration) {
                // remove stale entry
                delete anims[this[pauseId]];
              } else {
                // pause animation
                $(this).stop();
                data.run = false;
              }
            }
          });
        };

        $.fn.resume = function() {
          return this.each(function() {
            // check pauseId
            if (! this[pauseId])
              this[pauseId] = uuid++;
            // fetch data
            var data = anims[this[pauseId]];
            if (data && ! data.run) {
              // resume animation
              data.opt.duration -= data.done;
              data.done = 0;
              data.run = true;
              data.start = now();
              oldAnimate.apply($(this), [data.prop, $.extend({}, data.opt)]);
            }
          });
        };
      })();
      $(function(){
        var setElm = $('.loopSlider'),
          slideSpeed = 7000;

        setElm.each(function(){
          var self = $(this),
            selfWidth = self.innerWidth(),
            findUl = self.find('.c-block-gallery__block'),
            findLi = findUl.find('.c-block-gallery__list'),
            listWidth = findLi.outerWidth(),
            listCount = findLi.length,
            loopWidth = listWidth * listCount;

          findUl.wrapAll('<div class="loopSliderWrap" />');
          var selfWrap = self.find('.loopSliderWrap');

            findUl.css({width:loopWidth}).clone().appendTo(selfWrap);

            selfWrap.css({width:loopWidth*2});

            function loopMove(){
              selfWrap.animate({left:'-' + (loopWidth) + 'px'},slideSpeed*listCount,'linear',function(){
                selfWrap.css({left:'0'});
                loopMove();
              });
            };
            loopMove();

            setElm.hover(function() {
              selfWrap.pause();
            }, function() {
              selfWrap.resume();
            });
        });
      });
    }
    function textCut() {
      var outer = $('.c-card-post__title').innerWidth();
      if (outer > 180) {
        var cutFigure = '12';
      } else if (outer < 180) {
        var cutFigure = '11';
      } else if (outer < 170) {
        var cutFigure = '10';
      } else if (outer < 140) {
        var cutFigure = '7';
      }
      var $setElm = $('.c-card-post__title');
      var afterTxt = '…'; // 文字カット後に表示するテキスト

      $setElm.each(function(){
        var textLength = $(this).text().length;
        var textTrim = $(this).text().substr(0,(cutFigure))

        if(cutFigure < textLength) {
          $(this).html(textTrim + afterTxt).css({visibility:'visible'});
        } else if(cutFigure >= textLength) {
          $(this).css({visibility:'visible'});
        }
      });
    }

    $(function () {
      menuSlide();
      owlCarousel();
      modaal();
      reveal();
      autoWriteYear();
      buttonClick();
      cardSlider();
      textCut();
    });
  }
}

window.GApp = new App();
