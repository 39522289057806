/*
 * ====================================================================
 * Grow Template
 * @package  Grow Template
 * @author   GrowGroup.Inc <info@grow-group.jp>
 * @license  MIT Licence
 * ====================================================================
 */

import $ from "./app/jquery-shim";

(function ($) {
  var growApp = function () {



  };

  /************************
   * please insert your code
   *************************/
  growApp.prototype.tableResponsive = function () {

    let $postContent = $('.l-post-content');
    if ( $postContent.length == 0) {
      return false;
    }
    $postContent.find('table').wrap('<div class="js-responsive-table"></div>');

  };

  growApp.prototype.myCode = function () {

  }


  growApp.prototype.searchMore = function () {
    let $moreButton = $('.c-block-front-search__more-text');
    let $moreContent =$('.c-block-front-search__blocks');

    if ($moreButton.length == 0){
      return false;
    }

    $moreButton.on('click',function () {
      $moreContent.removeClass('is-hidden');
      $moreButton.hide();
    });
  };
  growApp.prototype.searchAccordion = function () {
    let $formTitle = $('.js-accordion-title');
    let $formContent = $('.js-accordion-content');

    // SP only
    var win = $(window).innerWidth();
    if (win > 750) {
      return false;
    }

    $formTitle.on('click',function () {
      $(this).toggleClass('is-open');
      $(this).next($formContent).toggleClass('is-active');
    });


  }

  $(function () {
    var app = new growApp();
    app.myCode();
    app.tableResponsive();
    app.searchMore();
    app.searchAccordion();
  });
})(jQuery);
